import React from "react";
import "./App.css";

import ParticlesBg from "particles-bg";

function App() {
  return (
    <>
      <div className="App">
        <h1>PrytzNET</h1>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://mail.prytznet.se"
          >
            webmail
          </a>
          <span> - </span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:vilhelm@prytznet.se"
          >
            vilhelm@prytznet.se
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vilhelmprytz.se"
          >
            copyright © 2016-2024 vilhelm prytz
          </a>
        </p>
      </div>
      <ParticlesBg type="thick" bg={true} />
    </>
  );
}

export default App;
